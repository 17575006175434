// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-product-no-shows-index-js": () => import("./../src/pages/product/no-shows/index.js" /* webpackChunkName: "component---src-pages-product-no-shows-index-js" */),
  "component---src-pages-products-index-js": () => import("./../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-returns-index-js": () => import("./../src/pages/returns/index.js" /* webpackChunkName: "component---src-pages-returns-index-js" */),
  "component---src-pages-terms-of-service-index-js": () => import("./../src/pages/terms-of-service/index.js" /* webpackChunkName: "component---src-pages-terms-of-service-index-js" */)
}

